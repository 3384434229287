@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter" var;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

p {
  font-family: "PPNeueMachina" !important;
  letter-spacing: 0.5px !important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.main-container {
  background: black;
  height: 100vh;
}

.nextui-navbar-container {
  max-width: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius:10px;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter Thin'), local('Inter-Thin'),
       url('./fonts/inter/Inter-Thin.ttf') format('truetype');
}


@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
       url('./fonts/inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter Medium'), local('Inter-Medium'),
       url('./fonts/inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
       url('./fonts/inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter Bold'), local('Inter-Bold'),
       url('./fonts/inter/Inter-Bold.ttf') format('truetype');
}
.reveal {
  position: relative;
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 0.25s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

